import React, { Fragment } from "react";

import DefaultPage from "../layout/DefaultPage";
import {
  RootBorder,
  ErrorLayout,
  Paragraph,
  H2
} from "../components/StyledComponent";

const gif = require("./../images/404.gif");

const NotFoundPage = () => {
  return (
    <Fragment>
      <DefaultPage pageTitle="Upps!">
        <ErrorLayout
          style={{
            background: `url(${gif})no-repeat center center`,
            backgroundSize: "cover"
          }}
        >
          <RootBorder />
          <section style={{ background: "var(--white)", padding: "1rem" }}>
            <H2
              style={{ marginBottom: "1rem" }}
              color="var(--black)"
              align="left"
            >
              Upps, no encontramos la pagina solicitada{" "}
              <span role="img" aria-label="Sad emoji">
                😔
              </span>
            </H2>
            <Paragraph color="var(--black)" align="left">
              Solicitaste una ruta que desconocemos.
            </Paragraph>
          </section>
        </ErrorLayout>
      </DefaultPage>
    </Fragment>
  );
};

export default NotFoundPage;
